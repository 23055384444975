.Block_RemoveButton {
    opacity: 0;
}

.Block:hover .Block_RemoveButton {
    opacity: 1;
}

/*
media query for mobiles
 */
@media (max-width: 768px) {
    .Block_RemoveButton {
        opacity: 1;
    }
}